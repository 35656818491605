import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './footer.module.scss';
import { baseUrls, termsUrls } from '../../library/constants/cms';
import PropTypes from 'prop-types';

const Footer = ({ userLanguage }) => {
    const { t } = useTranslation()
    const baseUrl = baseUrls[userLanguage] || 'https://www.leoprinting.com'; // Fallback URL
    const termsUrl = termsUrls[userLanguage]
    return (
        <footer>
            <div className="container">
                <div className={styles.footerWrapper}>
                    {t(`Copyright © ${new Date().getFullYear()} Leoprinting. Read our`)}&nbsp;
                    <a href={termsUrl} target="_blank" rel="noopener noreferrer">{t("Terms and Conditions.")}</a>&nbsp;{t("Go to")}&nbsp;
                    <a href={baseUrl} target="_blank" rel="noopener noreferrer"> {t("Leoprinting website")}</a>
                </div>
            </div>
        </footer>
    )
}
Footer.propTypes = {
    userLanguage: PropTypes.string
}

export default Footer