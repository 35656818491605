import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DELIVERY_TYPES } from '../../library/constants/constants.js';
import { getCmsFileUrl, getFilePath } from '../../library/utilities';
import { Image } from '../ui';
import styles from './quotation-item.module.scss';

const AdditionalQuotationItem = ({ itemDetails }) => {
    const { quotationToken } = useParams()
    const { t } = useTranslation()

    return (
        <div className={styles.wrapper}>
            <div className={styles.left}>
                <div className={styles.image}>
                    {itemDetails.product.main_photos && <Image src={getCmsFileUrl(itemDetails.product?.main_photos?.[0])} />}
                    <i>{t('Image is for illustration.Real product may vary')}</i>
                </div>
            </div>
            <div className={styles.right}>
                <h2>{itemDetails.product.name}</h2>
                <p className={styles.addressInfo}>{t('Address Information')} :</p>
                {itemDetails.delivery_addresses?.type === DELIVERY_TYPES.BULK ? (
                    <>
                        <p>{t('Bulk delivery to multiple addresses')}</p>
                        <p>{t('Notes')}: {itemDetails.delivery_addresses.deliveries?.notes}</p>
                        <a href={getFilePath(itemDetails.delivery_addresses.deliveries?.file, quotationToken)} className='button' download target="_self">{t('Download addresses')}</a>
                    </>
                ) : (
                    <table>
                        <tbody>
                            <tr>
                                <td className={styles.tableHeadingPadding}>{t('Quantity')}</td>
                                <td className={styles.tableHeadingPadding}>{t('Address')}</td>
                            </tr>{
                                itemDetails.delivery_addresses?.deliveries?.map(({ address, quantity }) => (
                                    <tr key={address.id} className={styles.greenShadowBox}>
                                        <td className={styles.tableValuePadding}>{quantity}</td>
                                        <td className={styles.tableValuePadding}>{address?.street_number}, {address?.street}, {address?.city}, {address?.county}, {address?.zipcode}, {address?.country?.name}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                )}
                {itemDetails.client_comment && <div>
                    <p className={styles.addressInfo}>Notes :</p>
                    <div className={styles.greenShadowBox}><div className={styles.tableValuePadding}>{itemDetails.client_comment}</div></div>
                </div>}
            </div>
        </div>

    )
}

AdditionalQuotationItem.propTypes = {
    itemDetails: PropTypes.object.isRequired,
}

export default AdditionalQuotationItem