
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Image } from '../../components/ui';
import { ItemClientStatuses } from '../../library/constants/client-statuses.js';
import { generateRouteUrl } from '../../library/constants/routes.jsx';
import { formatDate } from '../../library/utilities';
import ProgressBar from '../ui/progress-bar.jsx';
import styles from './project-item.module.scss';

const ProjectItem = ({ item, numbering }) => {
    const { t } = useTranslation();
    const { quotationToken } = useParams()
    const progress = useMemo(() => {
        if (item.client_status === ItemClientStatuses.CANCELLED) {
            return -1
        }
        let prg = 0
        if (item.client_status === ItemClientStatuses.ORDER_CONFIRMATION) {
            prg = 10
        }
        else {
            prg = Math.trunc((100 / 5) * (item.client_status - 1))
        }

        if (item.request_for_address === 2) {
            prg += 5
        }

        // 4. Clamp to a maximum of 100
        return Math.min(prg, 100)
    }, [item.client_status, item.request_for_address])

    return (
        <div className={`box--white ${styles.projectItem}`}>
            <div className={styles.header}>
                <div className={styles.productInfo}>
                    <h2>
                        <h5> {`Item-${numbering}`}</h5>
                        {item.product.name} <div className={styles.tooltip}> <img src="/icons/info.svg" />
                            <span className={styles.tooltiptext}>{item.specification.map(({ name, value }) => (
                                <React.Fragment key={name}>
                                    <div className={styles.name}>{name}</div>
                                    <div className={styles.value}>{value}</div>
                                </React.Fragment>
                            ))}</span>
                        </div></h2>
                    <Image src={`https://leoprinting.nl/files/photos/${item.product.main_photos?.[0]}`} width={300} height={250} className={styles.productImage} />
                </div>
                <div className={styles.progressBar}>
                    <ProgressBar value={progress} size="8rem" />
                </div>
                <div className={styles.itemDates}>
                    {item.client_expected_delivery_date && (
                        <div className="box--small box--blue-light">
                            <h3>{t('Requested Delivery Deadline')}</h3>
                            <div className={styles.date}>{formatDate(item.client_expected_delivery_date)}</div>
                        </div>
                    )}
                    {item.client_status < ItemClientStatuses.UPLOAD_ARTWORK && item.deadline_artwork_customer && (
                        <div className="box--small box--blue-light">
                            <h3>{t('Artwork submission deadline')}</h3>
                            <div className={styles.date}>{formatDate(item.deadline_artwork_customer)}</div>
                        </div>
                    )}
                    {item.client_status < ItemClientStatuses.IN_PRODUCTION && item.deadline_approval_proof_customer && (
                        <div className="box--small box--blue-light">
                            <h3>{t('Artwork approval deadling')}</h3>
                            <div className={styles.date}>{formatDate(item.deadline_approval_proof_customer)}</div>
                        </div>
                    )}
                    {(item.client_status === ItemClientStatuses.IN_TRANSIT && item.expected_delivery_date) && <div className="box--small box--blue-light">
                        <h3>{t("Expected Delivery")}</h3>
                        <div className={styles.date}>{formatDate(item.expected_delivery_date)}</div>
                    </div>
                    }
                </div>
            </div>
            <br />
            {item.item_additional_quotations && item.item_additional_quotations.map((quotation) => {
                return (
                    quotation.status === 3 && <div className={`box--blue-light ${styles.task}`}>
                        <div className={styles.taskContent}>
                            <span>{t("Additional Quotation")} : #{quotation.id}</span>
                        </div>
                        <div className={styles.taskAction}>
                            <Link to={generateRouteUrl('AdditionalQuotationDetails', {
                                quotationToken: quotationToken,
                                itemId: item.id,
                                quotationId: quotation?.id
                            })} className="button--primary"> {t("Review")} </Link>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

ProjectItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number,
        product: PropTypes.shape({
            name: PropTypes.string.isRequired,
            main_photos: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
        deadline_artwork_customer: PropTypes.string,
        order_status: PropTypes.number,
        client_expected_delivery_date: PropTypes.string,
        make_artwork_comment: PropTypes.string,
        request_for_template: PropTypes.number,
        client_confirm_address: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        template_comment: PropTypes.string,
        documents: PropTypes.object,
        client_status: PropTypes.number,
        specification: PropTypes.array,
        deadline_approval_proof_customer: PropTypes.string,
        expected_delivery_date: PropTypes.string,
        item_additional_quotations: PropTypes.array,
    })
}

export default ProjectItem