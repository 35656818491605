import React from 'react';
import { Outlet, useLoaderData, useNavigation } from 'react-router-dom';
import { changeLanguage } from '../../App.jsx';
import { getDocuments } from '../../api/project/project-item-artwork.js';
import { getProjectItemDetails } from '../../api/project/project-item.js';
import { getProjectDetails } from '../../api/project/project.js';
import Footer from '../../components/layout/footer.jsx';
import { HeaderBar } from '../../components/layout/header-bar.jsx';
import Header from '../../components/layout/header.jsx';

const DashboardRoot = () => {
    const navigation = useNavigation()
    const { project_id, userLanguage } = useLoaderData()

    return (
        <>
            <Header userLanguage={userLanguage} />
            <HeaderBar projectId={project_id} language={userLanguage} />
            <div className={`container page ${["loading", "submitting"].includes(navigation.state) ? 'loading' : ''}`}>
                <Outlet />
            </div>
            <Footer userLanguage={userLanguage} />
        </>
    );
}

DashboardRoot.Loader = async ({ params }) => {
    const { quotationToken } = params
    let userLanguage = navigator.language.split('-')?.[0] ?? 'en'

    const { items, id: project_id, cms_language_code, quotation } = await getProjectDetails(quotationToken)
    const projectItems = await Promise.all(items.map(({ id }) => getProjectItemDetails(quotationToken, id)))
    const projectDocs = await Promise.all(items.map(({ id }) => getDocuments(quotationToken, id)))
    const projectItemDetails = projectItems.map(item => ({
        documents: projectDocs.find(({ id }) => item.id === id),
        ...item,
    }))
    if (cms_language_code) {
        userLanguage = cms_language_code.split('_')?.[1] ?? cms_language_code
    }
    changeLanguage(userLanguage)

    return {
        projectItems: projectItemDetails,
        project_id,
        userLanguage,
        quotation,
    }
}

export default DashboardRoot
