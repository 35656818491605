export const baseUrls = {
    at: 'https://www.leoprinting.at',
    be_fr: 'https://www.leoprinting.be/fr',
    be: 'https://www.leoprinting.be',
    ch: 'https://www.leoprinting.ch',
    de: 'https://www.leoprinting.de',
    dk: 'https://www.leoprinting.dk',
    en: 'https://www.leoprinting.co.uk',
    es: 'https://www.leoprinting.es',
    fr: 'https://www.leoprinting.fr',
    nl: 'https://www.leoprinting.nl',
    lu_de: 'https://www.leoprinting.lu',
    lu_fr: 'https://www.leoprinting.lu/fr',
    it: 'https://www.leoprinting.it',
};

export const termsUrls = {
    at: 'https://www.leoprinting.at/bedingungen',
    be_fr: 'https://www.leoprinting.be/fr/conditions',
    be: 'https://www.leoprinting.be/voorwaarden',
    ch: 'https://www.leoprinting.ch/bedingungen?_gl=1*7bzjf2*_up*MQ..*_ga*MTIwNTUyNzY4NC4xNzM3NDQ5MzA2*_ga_ZSHH9BM3VX*MTczNzQ0OTMwNS4xLjAuMTczNzQ0OTMwNS4wLjAuMTgyMTE2NDc4MA..*_ga_RJ9Y0BQHQE*MTczNzQ0OTMwNS4xLjAuMTczNzQ0OTMwNS4wLjAuODI3MzY1NjAx',
    de: 'https://www.leoprinting.de/bedingungen',
    dk: 'https://www.leoprinting.dk/terms-and-conditions',
    en: 'https://www.leoprinting.co.uk/terms-and-conditions',
    es: 'https://www.leoprinting.es/condiciones',
    fr: 'https://www.leoprinting.fr/conditions',
    nl: 'https://www.leoprinting.nl/voorwaarden',
    lu_de: 'https://www.leoprinting.lu/bedingungen',
    lu_fr: 'https://www.leoprinting.lu/fr/conditions',
    it: 'https://www.leoprinting.it/informativa-sulla-privacy',
};

export const contactUrls = {
    at: 'https://www.leoprinting.at/contact',
    be_fr: 'https://www.leoprinting.be/fr/contact',
    be: 'https://www.leoprinting.be/contact',
    ch: 'https://www.leoprinting.ch/contact',
    de: 'https://www.leoprinting.de/contact',
    dk: 'https://www.leoprinting.dk/contact',
    en: 'https://www.leoprinting.co.uk/contact',
    es: 'https://www.leoprinting.es/contact',
    fr: 'https://www.leoprinting.fr/contact',
    nl: 'https://www.leoprinting.nl/contact',
    lu_de: 'https://www.leoprinting.lu/contact',
    lu_fr: 'https://www.leoprinting.lu/fr/contact',
    it: 'https://www.leoprinting.it/contact',
};

export const contactNumber = {
    at: '+49 (0) 211-63552690',
    be_fr: '+32 56 432 888',
    be: '+32 56 432 888',
    ch: '+49 (0) 211-63552690',
    de: '+49 (0) 211-63552690',
    dk: '+49 (0) 211-63552690',
    en: '+44 20 3608 1945',
    es: '+34 951 120 06',
    fr: '+33 36 688 0494',
    nl: '+31 72 711 2998',
    lu_de: '+49 (0) 211-63552690',
    lu_fr: '+33 36 688 0494',
    it: '+39 33 987 23314',
};

export const cmsLanguageCodes = [
    'at',
    'be_fr',
    'be',
    'ch',
    'de',
    'dk',
    'en',
    'es',
    'fr',
    'nl',
    'lu_de',
    'lu_fr',
    'it',
]