import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, redirect, useActionData, useFetcher, useLoaderData, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { uploadFileFromObject } from '../../api/common';
import { getCustomerAddresses } from '../../api/customer';
import { bulkUploadProjectItemAddresses, getProjectItemAddresses, getProjectItemDetails, updateProjectItemAddresses } from '../../api/project';
import AddressBox from '../../components/data/address-box.jsx';
import { AddressSelector } from '../../components/project';
import { Image, Message, MessageTypes, Modal } from '../../components/ui';
import SingleFileUpload from '../../components/ui/multifile-upload.jsx';
import { useMultipleAddresses } from '../../hooks';
import i18n from '../../i18n.js';
import { ActionError, ValidationError } from '../../library/classes/exceptions';
import { getCmsFileUrl, getFilePath } from '../../library/constants';
import { generateRouteUrl } from '../../library/constants/routes.jsx';
import { getCountryById } from '../../library/helper/country.js';
import styles from './project-item-address.module.scss';

const ADDRESS_TYPES = {
    SINGLE: "single",
    MULTIPLE: "multiple",
    BULK: "bulk"
}

const getPreviewForAddress = (file) => {
    switch (file.type) {
        case "application/csv":
            return "/icons/filetypes/csv.png"
        default:
            return "/icons/filetypes/csv.png"
    }
}
const BulkFile = ({ name, path, quotationToken }) => {
    const { t } = useTranslation()
    if (!path) {
        return <>ERROR</>
    }
    return (
        <div >
            <img src={getPreviewForAddress(path)} alt='' width={'100px'} height={'100px'} />
            <div className={styles.wrapper}>
                <span><b>{t("Name")}</b> : {name}</span>
                <Link reloadDocument to={getFilePath(path, quotationToken)} className="button"><img src="/icons/download.svg" alt='download' />Download</Link>
            </div>
        </div>
    )
}
BulkFile.propTypes = {
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    quotationToken: PropTypes.string.isRequired,
}

const SingleDeliveryAddress = ({ customerAddresses, isConfirmed, selectedAddress, onChange }) => {
    const [address, setAddress] = useState(selectedAddress)
    const [addressPreview, setAddressPreview] = useState(false)
    const { t } = useTranslation()
    const fetcher = useFetcher()
    const { errors = {} } = useActionData() || {}
    const handleConfirm = (address) => {
        try {
            fetcher.submit({
                ...address,
                action: 'singleDeliveryAddress'
            }, {
                method: 'post',
                encType: 'application/json'
            })
        }
        catch (e) {
            return { error: e }
        }
    }

    useEffect(() => {
        if (typeof onChange !== 'function') {
            return
        }
        onChange([{ ...address }])
    }, [address, onChange])

    return (
        <div>
            {isConfirmed ? <div>
                <p>{t("Below is the Address confirmed by you")}</p>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                    <AddressBox address={customerAddresses.find(add => add.id === address?.address_id)} />
                    <p>{t("Notes")} : {address.notes}</p>
                </div>
            </div> : (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <div className={`box--light-gray ${styles.addressBox}`}>
                        <AddressSelector
                            name="address_id"
                            addresses={customerAddresses}
                            selectedAddressId={address?.address_id}
                            onChange={(id) => { setAddress({ ...address, address_id: id }) }}
                            excludedAddresses={address ? [address.id] : []}
                        />
                        {!!errors.address_id && errors.address_id.map(error => <p key={error} className='error'>{error}</p>)}
                        <label>{t("Notes")}</label>
                        <textarea name="notes" rows={4} style={{ width: "100%" }} defaultValue={address?.notes}
                            onChange={(e) => setAddress({ ...address, notes: e.target.value })}
                        />
                    </div>
                    <div className="actions align--right">
                        <button className="button--primary" onClick={() => { setAddressPreview(true) }}>{t('Save')}</button>
                    </div>
                    <Modal isVisible={addressPreview} onClose={() => {
                        setAddressPreview(false)
                    }}>
                        <div className={fetcher.state !== 'idle' ? 'loading' : ''}>
                            <p>{t("Please confirm your selection of this address as it will be final and cannot be changed later.")}</p>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                                <AddressBox address={customerAddresses.find(add => add.id === address.address_id)} />
                                <p>{t("Notes")} : {address.notes}</p>
                                <p>{t("Quantity")} : {address.quantity}</p>
                            </div>
                            <div className="actions">
                                <button className="button--danger button--large" onClick={() => setAddressPreview(false)} disabled={fetcher.state !== 'idle'}>{t("Cancel")}</button>
                                <button className="button--primary" onClick={() => { handleConfirm(address) }} disabled={fetcher.state !== 'idle'}>{t("Save")}</button>
                            </div>
                        </div>
                    </Modal>
                </div>
            )
            }

        </div>
    )
}
SingleDeliveryAddress.propTypes = {
    customerAddresses: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    isConfirmed: PropTypes.bool,
    selectedAddress: PropTypes.object,
}

const MultipleDeliveryAddresses = ({ customerAddresses, isConfirmed, itemAddresses, totalQuantity, addressesCount, onChange }) => {
    const { t } = useTranslation()
    const [addresses, updateAddress, isValid] = useMultipleAddresses({ initialAddresses: itemAddresses, totalQuantity, addressesCount, onChange })
    const { errors = {} } = useActionData() || {}
    const [addressPreview, setAddressPreview] = useState(false)
    const fetcher = useFetcher()

    const handleConfirm = (addresses) => {
        try {
            fetcher.submit({
                addresses,
                action: 'multipleDeliveryAddress'
            }, {
                method: 'post',
                encType: 'application/json'
            })
        }
        catch (e) {
            return { error: e }
        }
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            {isConfirmed ? (
                <div>
                    <p>{t("Below are the addresses confirmed by you")}</p>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }} >
                        {addresses.map((address) => (
                            <div key={address.id} >
                                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                                    <AddressBox address={customerAddresses.find(add => add.id === address.address_id)} />
                                    <p>Notes : {address.notes}</p>
                                    <p>Quantity : {address.quantity}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <>
                    {addresses.map(({ id, address_id, quantity, notes }, index) => (
                        <div key={id} className={`box--light-gray ${styles.addressBox}`}>
                            <label>
                                {t('Quantity')}
                                <input
                                    type="number"
                                    min={0}
                                    step={1}
                                    max={totalQuantity}
                                    name={`addresses[${index}][quantity]`}
                                    value={quantity}
                                    onChange={(e) => updateAddress(id, { address_id, notes, quantity: e.target.valueAsNumber })}
                                    onWheel={(e) => updateAddress(id, { address_id, notes, quantity: e.target.valueAsNumber })}
                                    onFocus={e => e.target.select()}
                                    required
                                />
                                {!!errors[`addresses[${index}][quantity]`] && errors[`addresses[${index}][quantity]`].map((error, index) => <p key={index} className='error'>{error}</p>)}
                            </label>
                            {!!errors[`addresses[${index}][address_id]`] && errors[`addresses[${index}][address_id]`].map((error, index) => <p key={index} className='error'>{error}</p>)}
                            <AddressSelector
                                name={`addresses[${index}][address_id]`}
                                addresses={customerAddresses}
                                selectedAddressId={address_id ?? null}
                                onChange={(address_id) => updateAddress(id, { address_id, notes, quantity })}
                                excludedAddresses={addresses.map(({ address_id }) => address_id).filter(v => v !== address_id)}
                            />
                            <label>{t("Notes")}</label>
                            <textarea name={`addresses[${index}][notes]`} rows={4} style={{ width: "100%" }} defaultValue={notes} onChange={(e) => updateAddress(id, { address_id, quantity, notes: e.target.value })} />
                        </div>
                    ))}

                </>
            )}
            <Modal isVisible={addressPreview} onClose={() => {
                setAddressPreview(false)
            }}>
                <div className={fetcher.state !== 'idle' ? 'loading' : ''}>
                    <p>{t("Please confirm your selection of these addresses as it will be final and cannot be changed later.")}</p>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }} >
                        {addresses.filter(({ address_id }) => !!address_id).map((address) => {
                            return <div key={address.id} >
                                <div>
                                    <AddressBox address={customerAddresses.find(add => add.id === address.address_id)} />
                                    <p>Notes : {address.notes}</p>
                                    <p>Quantity : {address.quantity}</p>
                                </div>
                            </div>
                        })}

                    </div>
                    <br />
                    <div className="actions">
                        <button className="button--danger button--large" onClick={() => setAddressPreview(false)} disabled={fetcher.state !== 'idle'}>{t("Cancel")}</button>
                        <button className="button--primary button--large" onClick={() => { handleConfirm(addresses) }} disabled={fetcher.state !== 'idle'}>{t("Yes")}</button>
                    </div>
                </div>
            </Modal >
            {!isConfirmed && <div className="actions align--right">
                <button className="button--primary" onClick={() => { setAddressPreview(true) }} disabled={!isValid}>{t('Save')}</button>
            </div>}
        </div >
    )
}
MultipleDeliveryAddresses.propTypes = {
    customerAddresses: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    itemAddresses: PropTypes.arrayOf(PropTypes.object).isRequired,
    addressesCount: PropTypes.number.isRequired,
    totalQuantity: PropTypes.number.isRequired,
    isConfirmed: PropTypes.bool,

}

const BulkDeliveryAddresses = ({ isConfirmed, uploadedFile, quotationToken }) => {
    const { t } = useTranslation()
    const { errors = {} } = useActionData() || {}
    const [addressPreview, setAddressPreview] = useState(false)
    const fileUploaderRef = useRef([])
    const [isLoading, setIsLoading] = useState(false)
    const fetcher = useFetcher()

    useEffect(() => {
        setIsLoading(fetcher.state !== 'idle')
    }, [fetcher.state])

    const handleFilesUpload = async () => {
        if (fileUploaderRef.current.length === 0) {
            alert(i18n.t('Please select atleast one file'))
            return
        }
        setIsLoading(true)
        const promises = fileUploaderRef.current.map(file => uploadFileFromObject(quotationToken, file))
        try {
            const fileIds = await Promise.all(promises)
            const fileId = fileIds.pop()
            fetcher.submit({
                fileId,
                action: 'bulkDeliveryAddress'
            }, {
                method: 'post',
                encType: 'application/json'
            })
        }
        catch (error) {
            console.log(error);
            setIsLoading(false)
            return new Response('Unable to upload files', { status: 200 })
        }
        setIsLoading(false)
    }

    return (
        isConfirmed ? <div>
            <p>{t("Below is the uploaded addresses file confirmed by you")}</p>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }} >
                {uploadedFile && <BulkFile name={uploadedFile.name} path={uploadedFile.url} quotationToken={quotationToken} />}
            </div>
        </div> : <div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }} encType="multipart/form-data" >
                {!!errors.addressFile && errors.addressFile.map((error, index) => <p key={index} className='error'>{error}</p>)}
                <div className={`box--light-gray ${styles.addressSelector}`}>
                    <p>{t("Please download the template sample and add addresses and then upload the file.")}</p>
                    <Link to="/path/to/sampleaddresses" download target="_blank" className="button--primary">{t("Download Sample")}</Link>
                    <br />
                    <div className="actions align--left">
                        <SingleFileUpload ref={fileUploaderRef} />


                        {/* <label htmlFor="file" className="button">{t("Upload File")}</label>
                        <input id="file" name="addressFile" style={{ visibility: "hidden", maxWidth: 0 }} type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /> */}
                    </div>
                    <i>{t("Supported formats : CSV ,XLSX")}</i>
                </div>
                <Modal isVisible={addressPreview} onClose={() => {
                    setAddressPreview(false)
                }}>
                    <div className={isLoading ? 'loading' : ''}>
                        <p>{t("Please confirm if you intended to upload these addresses, as changes cannot be made afterwards")}</p>
                        <div className="actions">
                            <button className="button--danger button--large" onClick={() => setAddressPreview(false)} disabled={isLoading}>{t("Cancel")}</button>
                            <button className="button--primary button--large" onClick={() => { handleFilesUpload() }} disabled={isLoading}>{t("Yes")}</button>
                        </div>
                    </div>
                </Modal >
                <div className="actions align--right">
                    <button className="button--primary" onClick={() => { setAddressPreview(true) }} >Confirm Delivery Address</button>
                </div>
            </div>
        </div>
    )

}
BulkDeliveryAddresses.propTypes = {
    uploadedFile: PropTypes.string,
    quotationToken: PropTypes.string.isRequired,
    isConfirmed: PropTypes.bool

}

const ProjectItemAddress = () => {
    const { quotationToken } = useParams()
    const { t } = useTranslation()
    const { projectItem, itemAddresses, customerAddresses } = useLoaderData()
    const [deliveriesCount, setDeliveriesCount] = useState(itemAddresses?.length)
    const [selectedAddresses, setSelectedAddresses] = useState([...itemAddresses])

    const [addressType, setAddressType] = useState(
        (projectItem.delivery_type === 3) ?
            ADDRESS_TYPES.BULK :
            itemAddresses?.length > 1 ?
                ADDRESS_TYPES.MULTIPLE :
                ADDRESS_TYPES.SINGLE
    )

    useEffect(() => {
        const _addressType = deliveriesCount > 5 ? ADDRESS_TYPES.BULK :
            deliveriesCount > 1 ? ADDRESS_TYPES.MULTIPLE :
                ADDRESS_TYPES.SINGLE
        console.log({_addressType})
        setAddressType(_addressType)
    }, [deliveriesCount])

    const actionData = useActionData()

    const handleAddressChange = useCallback((addresses) => {
        setSelectedAddresses(addresses.map(address => ({
            ...customerAddresses.find(({ id }) => id === address.address_id),
            ...address
        })))
    }, [])

    const remainingQuantity = useMemo(() => {
        return selectedAddresses.reduce((acc, curr) => ((!!curr.address_id && curr.quantity > 0) ? acc - curr.quantity : acc), projectItem.quantity)
    }, [selectedAddresses])

    return (
        <div className="wrapper addresses-wrapper">
            <div className="main">
                <h2 className="main--title">
                    <img src="/icons/add-location.svg" alt="" />
                    {t("Delivery Address")}
                </h2>
                <div className="box--white">
                    <div className={styles.header}>
                        <h2>{projectItem.product.name}</h2>
                        <Link to={generateRouteUrl('Dashboard', { quotationToken })} className="button"><img src="/icons/arrow.svg" alt="back" />{t("Back")}</Link>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.contentLeft}>
                            <Image src={getCmsFileUrl(projectItem.product?.main_photos?.[0])} width={300} height={200} className={styles.productImage} />
                            <div className="box--small box--light-gray">{t("Approved Quantity")} : {projectItem.quantity}</div>
                            {addressType === ADDRESS_TYPES.MULTIPLE && (
                                <>
                                    <hr />
                                    <h3 style={{ padding: 'var(--space-small)' }}>{t('Summary')}</h3>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                        {selectedAddresses.filter(a => !!a.address_id).map(address => (
                                            <div key={address.id} className="box--light-gray box--small">
                                                <div className="box__heading"><strong>{t('Quantity')}: {address?.quantity}</strong></div>
                                                <span>
                                                    {[
                                                        address.street,
                                                        address.street_number,
                                                        address.city,
                                                        address.county,
                                                        getCountryById(address.country_id)?.name,
                                                        address.zipcode,
                                                    ].filter(Boolean).join(', ')}
                                                </span>
                                            </div>
                                        ))}
                                        {remainingQuantity > 0 && (
                                            <div className='danger' style={{ padding: '0 var(--space-small)' }}>{t('Remaining Quantity')}: {remainingQuantity}</div>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                        {<div className={styles.contentRight}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }} >
                                {t("Delivery Address")}
                                {!projectItem.client_confirm_address && (
                                    <div className="box--light-gray">
                                        <p>{t("Select number of deliveries required")}</p>
                                        <input type="number" value={deliveriesCount} min={1} onChange={e => {
                                            if (isNaN(parseInt(e.target.value))) {
                                                setDeliveriesCount(1)
                                            }
                                            else {
                                                setDeliveriesCount(parseInt(e.target.value))
                                            }
                                        }} />
                                    </div>
                                )}
                                {!!actionData && actionData instanceof ActionError && <Message type={MessageTypes.ERROR} message={actionData.message} timeOut={3} />}
                                {actionData === true && <Message type={MessageTypes.SUCCESS} message="Address updated successfully" timeOut={3} />}
                                {addressType === ADDRESS_TYPES.SINGLE && <SingleDeliveryAddress isConfirmed={projectItem.client_confirm_address != null} customerAddresses={customerAddresses} selectedAddress={itemAddresses.map(({ address_id, notes, quantity }) => ({ address_id, notes, quantity }))?.[0]} onChange={handleAddressChange} />}
                                {addressType === ADDRESS_TYPES.MULTIPLE && <MultipleDeliveryAddresses isConfirmed={projectItem.client_confirm_address != null} customerAddresses={customerAddresses} itemAddresses={itemAddresses.map(({ address_id, notes, quantity }) => ({ address_id, notes, quantity }))} totalQuantity={projectItem.approved_quantity} addressesCount={deliveriesCount} onChange={handleAddressChange} />}
                                {addressType === ADDRESS_TYPES.BULK && <BulkDeliveryAddresses isConfirmed={projectItem.client_confirm_address != null} uploadedFile={projectItem.item_delivery_addresses?.[0]?.bulk_address_file} quotationToken={quotationToken} />}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            <Outlet />
        </div>
    )
}

ProjectItemAddress.Actions = {
    singleDeliveryAddress: async ({ params, data }) => {
        const { quotationToken, itemId } = params
        const { address_id, notes } = data
        try {
            if (!address_id || !parseInt(address_id)) {
                return {
                    error: [{
                        path: 'address_id',
                        message: 'Address is required'
                    }]
                }
            }
            const parsedAddresses = [{
                address_id: parseInt(address_id),
                notes,
            }]
            await updateProjectItemAddresses(quotationToken, itemId, parsedAddresses)
            return redirect(generateRouteUrl('Dashboard', { quotationToken }))
        }
        catch (e) {
            return e
        }

    },
    multipleDeliveryAddress: async ({ params, data }) => {
        const { quotationToken, itemId } = params
        const { addresses } = data

        try {
            const parsedAddresses = Object.values(addresses).map(({ address_id, quantity, notes }) => ({
                address_id: parseInt(address_id),
                quantity: parseInt(quantity),
                notes
            }))
            //Check if all addresses and quantities are filled
            parsedAddresses.forEach(({ address_id, quantity }, index) => {
                if (!address_id) {
                    throw new ValidationError('Address is required', [{
                        path: `addresses[${index}][address_id]`,
                        message: 'Please select an address'
                    }])
                }
                if (!quantity) {
                    throw new ValidationError('Quantity is required', [{
                        path: `addresses[${index}][quantity]`,
                        message: 'Please select a quantity'
                    }])
                }
            })
            await updateProjectItemAddresses(quotationToken, itemId, parsedAddresses)
            return redirect(generateRouteUrl('Dashboard', { quotationToken }))

        }
        catch (error) {
            return error
        }
    },
    bulkDeliveryAddress: async ({ params, data }) => {
        const { quotationToken, itemId } = params
        const { fileId } = data
        try {
            await bulkUploadProjectItemAddresses(quotationToken, itemId, fileId)
            return redirect(generateRouteUrl('Dashboard', { quotationToken }))

        }
        catch (e) {
            toast.error(e?.message)
            return { error: e }
        }

    },
}

ProjectItemAddress.Loader = async ({ params }) => {
    const { quotationToken, itemId } = params
    const projectItem = await getProjectItemDetails(quotationToken, itemId)
    const itemAddresses = await getProjectItemAddresses(quotationToken, itemId)
    const customerAddresses = await getCustomerAddresses(quotationToken)
    return { projectItem, itemAddresses, customerAddresses }
}

export default ProjectItemAddress