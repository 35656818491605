import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice, getCmsFileUrl } from '../../library/utilities';
import { Image } from '../ui';
import styles from './quotation-item.module.scss';

const QuotationItem = ({ item, selectedId = null, onSelectionChange, currency }) => {
    const { t } = useTranslation()
    return (
        <div className={styles.wrapper}>
            <div className={styles.left}>
                <div className={styles.image}>
                    {item.product.photos && <Image src={getCmsFileUrl(item.product?.photos?.[0])} />}
                    <i style={{ fontSize: '0.8rem' }}>{t('Image is for illustration.Real product may vary')}</i>
                </div>
            </div>
            <div className={styles.right}>
                <h2>{item.product.name}</h2>
                <div className={styles.specifications}>
                    {item.specifications.map(({ name, value }) => (
                        <React.Fragment key={name}>
                            <div className={styles.name}>{name}</div>
                            <div className={styles.value}>{value}</div>
                        </React.Fragment>
                    ))}
                </div>
                <QuotationItemQuantities currency={currency} prices={item.prices} selectedId={selectedId} onSelectionChange={(selected) => {
                    onSelectionChange({ itemId: item.id, priceId: selected })
                }} />
            </div>
        </div>
    )
}

QuotationItem.propTypes = {
    item: PropTypes.object.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    selectedId: PropTypes.number,
    currency: PropTypes.object
}

const QuotationItemQuantities = ({ prices, onSelectionChange, selectedId = null, currency }) => {
    const { t } = useTranslation()
    const [selected, setSelected] = useState(selectedId)
    const handleRowSelect = (row) => {
        const _sel = row.id === selected ? null : row.id
        setSelected(_sel)
        onSelectionChange(_sel)
    }

    return (
        <div className={styles.quantities}>
            <div className={`${styles.quantityHeading} ${styles.quantityRow}`}>
                <div>{t('Quantity')}</div>
                <div>{t('Sales Price/Piece')}</div>
                <div>{t('Total Amount (excl. VAT)')}</div>
            </div>
            {prices.map(price => (
                <div role='button' tabIndex={0} className={styles.quantityRow} key={price.id} onClick={() => handleRowSelect(price)}>
                    <div className={styles.quantityCheckbox}>
                        <label className="checkbox">
                            <input type="checkbox" checked={selected === price.id} readOnly />
                            <span className="indicator"></span>
                        </label>
                    </div>
                    <div>{price.quantity}</div>
                    <div>{formatPrice(price.sales_price, currency.code)}</div>
                    <div>{formatPrice(price.total_price_excl_vat, currency.code)}</div>
                </div >
            ))}
        </div>
        // <table style={{ width: '100%' }}>
        //     <thead>
        //         <tr >
        //             <td>{t('Quantity')}</td>
        //             <td>Sales Price/Piece</td>
        //             <td>Total Amount</td>
        //             <td></td>
        //         </tr>
        //     </thead>
        //     <tbody>
        //         {prices.map(price => (
        //             <tr key={price.id} onClick={() => handleRowSelect(price)}>
        //                 <td className={styles.cell}>{(price.quantity)}</td>
        //                 <td className={styles.cell}>  {formatPrice(price.sales_price, currency?.code)}</td>
        //                 <td className={styles.cell}> {formatPrice(price.total_price, currency?.code)}</td>
        //                 <td className={styles.quantityCheckbox}>
        //                     <label htmlFor="" className="checkbox">
        //                         <input type="checkbox" checked={selected === price.id} readOnly />
        //                         <span className="indicator"></span>
        //                     </label>
        //                 </td>
        //             </tr >
        //         ))}
        //     </tbody>
        // </table>
    )
}

QuotationItemQuantities.propTypes = {
    prices: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    currency: PropTypes.object.isRequired,
    selectedId: PropTypes.number,
}

export default QuotationItem