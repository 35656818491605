import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Await, defer, Link, redirect, useFetcher, useLoaderData, useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteQuotation, getVisuals } from '../../api/quotation';
import { getEnvVariable } from '../../App.jsx';
import { QuotationItem } from '../../components/quotation';
import { Modal } from '../../components/ui';
import { Prompt } from '../../components/ui/prompt.jsx';
import { useHubspotChat } from '../../hooks/hubspot.js';
import i18n from '../../i18n.js';
import { generateRouteUrl } from '../../library/constants/routes.jsx';
import { formatPrice, getFilePath } from '../../library/utilities';
import { isEmpty } from '../../library/utilities/common.js';
import { LocalStorage } from '../../library/utilities/localstorage.js';
import styles from './quotation-details.module.scss';
import Confirm from '../../components/ui/confirm-modal.jsx';

const QuotationDetails = () => {
    const { t } = useTranslation()
    const { quotationToken } = useParams()
    const { quotation, status } = useRouteLoaderData('QuotationRoot') ?? {}
    const { savedSelections, visual } = useLoaderData()
    const [selections, setSelections] = useState(savedSelections)
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false)
    const navigate = useNavigate()
    const fetcher = useFetcher()
    const { openHandler } = useHubspotChat(getEnvVariable('VITE_HUBSPOT_APP_ID'));

    const selectedItems = useMemo(() => {
        if (isEmpty(selections) || !quotation) {
            return []
        }
        return Object.entries(selections).map(([itemId, priceId]) => {
            const selectedItem = quotation.items.find(i => i.id === Number(itemId))
            const selectedItemPrice = selectedItem?.prices?.find(p => p.id === priceId)
            if (!selectedItemPrice) {
                return null
            }
            const { quantity, total_price_excl_vat, vat = null } = selectedItemPrice
            return ({
                id: selectedItem.id,
                name: selectedItem.product.name,
                quantity,
                vat,
                price: total_price_excl_vat,
            })
        }).filter(a => a !== null)
    }, [quotation, selections])


    const totalVat = useMemo(() => {
        return selectedItems.reduce((acc, { vat }) => acc + vat?.vat_amount, 0)
    }, [selectedItems])

    const selectedItemsTotal = useMemo(() => {
        let _total = selectedItems.reduce((acc, { price }) => acc + price, 0)
        if (quotation.include_vat) {
            _total += totalVat
        }
        return _total
    }, [selectedItems, totalVat, quotation.include_vat])

    const handleConfirmClick = () => {
        /**
         * Todo: add modal to confirm if not all items are selected
         */
        //Check if all items are selected
        if (selectedItems.length < quotation.items.length) {
            setIsConfirmationModalVisible(true)
            return
        }

        gotoOrderConfirm()
    }

    const rejectQuotation = async () => {
        fetcher.submit({
            action: 'rejectQuotation'
        }, {
            method: 'post',
            encType: 'application/json'
        })
    }

    const gotoOrderConfirm = () => {
        //redirect to quotation preview, with selected data
        LocalStorage(quotationToken).set('selectedQuotationItems', selections)
        navigate(generateRouteUrl('QuotationPreview', { quotationToken }))
    }

    return (
        <>
            <div>
                {!quotation ?
                    status === false && <div className="box--light-red">
                        <p>{t('Quotation Canceled')}:</p>
                        <p>{t("The Quotation that you are looking for is either canceled of deleted, please check you mail for updates")}</p>
                    </div> :
                    <div>
                        <div className="box--light-red" style={{ textAlign: 'center' }}>
                            <h2>{t('Instructions')}</h2>
                            <p>{t("Review Your Order: After selecting your items and quantities, you'll be redirected to an order overview page.Please review your items and their details.Scroll to Sign: After reviewing, scroll down to find the 'Sign' button.")}</p>
                        </div>
                        <div className="wrapper quotation-wrapper">
                            <div className="main">
                                {!!quotation.items && quotation.items.map(item => <QuotationItem key={item.id} currency={quotation.currency} item={item} selectedId={selections?.[item.id]} onSelectionChange={({ itemId, priceId }) => {
                                    if (priceId) {
                                        setSelections({ ...selections, [itemId]: priceId })
                                    }
                                    else {
                                        const { [itemId]: _, ..._rest } = selections
                                        setSelections(_rest)
                                    }
                                }} />)}
                            </div>
                            <div className={`sidebar${fetcher.state !== 'idle' ? ' loading' : ''}`}>
                                {selectedItems.length > 0 && (
                                    <div className="box--gray box--small ">
                                        <h3>{t('Overview')}</h3>
                                        <div className={styles.totals}>
                                            <table className={styles.totalRow}>
                                                <thead>
                                                    <tr>
                                                        <th><strong>{t('Item Name')}</strong></th>
                                                        <th><strong>{t('Quantity')}</strong></th>
                                                        <th><strong>{t('Total Amount')}</strong></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {selectedItems.map(item => (
                                                        <React.Fragment key={item.id}>
                                                            <tr>
                                                                <td>{item.name}</td>
                                                                <td>{item.quantity}</td>
                                                                <td> {formatPrice(item.price, quotation.currency?.code)}</td>
                                                            </tr>
                                                            {!!quotation.include_vat && !!item.vat && (
                                                                <tr>
                                                                    <td><i>{t('VAT')} - ({item.vat?.vat_rate}%)</i></td>
                                                                    <td></td>
                                                                    <td>{formatPrice(item.vat?.vat_amount, quotation.currency?.code)}</td>
                                                                </tr>
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                </tbody>
                                                <tfoot>
                                                    {!!quotation.include_vat && !isNaN(totalVat) && (
                                                        <tr>
                                                            <td>{t('Total VAT')}</td>
                                                            <td></td>
                                                            <td>{formatPrice(totalVat, quotation.currency?.code)}</td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <th colSpan="2">{t('Total Amount')}</th>
                                                        <th> {formatPrice(selectedItemsTotal, quotation.currency?.code)}</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                )}

                                <div className="actions--expand">

                                    {
                                        selectedItems.length === 0 ?
                                            <div className={styles.tooltip}> <button className="button--primary button--large" onClick={handleConfirmClick} disabled={selectedItems.length === 0}
                                            >{t('Confirm Order')}</button>
                                                <span className={styles.tooltiptext}>{t('Please Select at least one quantity')}</span>
                                            </div> :
                                            <button className="button--primary button--large" onClick={handleConfirmClick} disabled={selectedItems.length === 0}
                                            >{t('Confirm Order')}</button>
                                    }

                                    {/* <button className="button--large" onClick={() => { setRejectCommentVisible(true) }}>{t('Reject')}</button> */}
                                    <Confirm
                                        title={t('Confirm Cancellation')}
                                        className="button--large"
                                        description={(
                                            <>
                                                <p>{t('Before proceeding with cancellation, we\'d love the opportunity to address any concerns or questions you may have. Please consider reaching out to us first — we’re here to help find a solution. If you still wish to cancel, you may proceed, but note that the quotation will no longer be valid, and you will be redirected to complete a feedback form.')}</p>
                                                <button className={`button--plain ${styles.chatButton}`} onClick={openHandler}>
                                                    <img src="/icons/chat.svg" />
                                                    {t('Chat with us')}
                                                </button>
                                            </>
                                        )}
                                        cancelButtonText={t('Go back')}
                                        confirmButtonText={t('Yes')}
                                        cancelButtonProps={{
                                            className: "button--primary button--large"
                                        }}
                                        confirmButtonProps={{
                                            className: "button--danger button--large"
                                        }}
                                        onConfirm={rejectQuotation}
                                    >{t('Reject')}</Confirm>
                                </div>
                                <Link reloadDocument to={getFilePath(quotation.files?.quotation, quotationToken)} className={styles.quotationDownload} download target="_self">
                                    <div className={styles.quotationDownload__text}>
                                        <h3>{t('Download Quotation PDF')}</h3>
                                        <span>{t('Original quotation shared by LeoPrinting')}</span>
                                    </div>
                                    <img src="/icons/pdf.svg" className={styles.quotationDownload__icon} />
                                </Link>
                                <React.Suspense>
                                    <Await resolve={visual}>
                                        {(visual) => {
                                            return (!!visual && visual.length > 0) && (
                                                <Link reloadDocument to={generateRouteUrl('QuotationVisual', {
                                                    quotationToken
                                                })} className={styles.visualButton}>
                                                    <div className={styles.visualButtonTitle}>
                                                        <img src="/icons/visual.svg" className={styles.quotationDownload__icon} />
                                                        <h3>{t('Visual is available!')}</h3>
                                                    </div>
                                                    <span>{t('click here to see the file.')}</span>
                                                </Link>
                                            )
                                        }}
                                    </Await>
                                </React.Suspense>
                                <Modal title={t('Confirm Order')} onClose={() => { setIsConfirmationModalVisible(false) }} isVisible={isConfirmationModalVisible}>
                                    <p>{t('Not all items are selected. Are you sure you want to continue ?')}</p>
                                    <div className="actions">
                                        <button className="button--danger button--large" onClick={() => setIsConfirmationModalVisible(false)}>{t('Cancel')}</button>
                                        <button className="button--primary button--large" onClick={() => gotoOrderConfirm()}>{t('Yes')}</button>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>

                }


            </div>
        </>
    )
}

QuotationDetails.Actions = {
    rejectQuotation: async ({ params }) => {
        const { quotationToken } = params
        try {
            const { data } = await deleteQuotation(quotationToken)
            toast.success(i18n.t('Quotation Rejected Successfully'))
            return redirect(data.feedback_url)
        } catch (e) {
            toast.error(i18n.t(e?.message))
        }
        return true
    }
}

QuotationDetails.Loader = async ({ params }) => {
    const { quotationToken } = params
    // const { quotation = null, cms_language_code = 'en' } = await getQuotationDetails(quotationToken)
    // if (!quotation || isEmpty(quotation)) {
    //     return redirect(generateRouteUrl('Dashboard', { quotationToken }))
    // }

    // //If items already selected

    const visual = getVisuals(quotationToken)

    const savedSelections = LocalStorage(quotationToken).get('selectedQuotationItems') || {}

    return defer({ savedSelections, visual })
}

export default QuotationDetails
