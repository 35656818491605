export function formDataToObject(formData) {
    const data = {};

    for (let [key, value] of formData.entries()) {
        let keys = key.split(/\[(.*?)\]/).filter((k) => k); // split on brackets
        let obj = data;

        keys.forEach((k, index) => {
            if (!isNaN(parseInt(k))) { // if key is a number, initialize array
                obj = obj || [];
                obj = obj[parseInt(k)] = obj[parseInt(k)] || {};
            } else if (index === keys.length - 1) {
                obj[k] = value;
            } else {
                obj[k] = obj[k] || {};
                obj = obj[k];
            }
        });
    }

    return data;
}

export const parseFormData = (formData) => {
    let parsedData = {}
    formData.forEach((value, key) => {
      // Check if the key already exists
      if (Object.prototype.hasOwnProperty.call(parsedData, key)) {
        // If it's an array, push the new value
        if (Array.isArray(parsedData[key])) {
          parsedData[key].push(value);
        } else {
          // If it's not an array, convert it to an array and add the new value
          parsedData[key] = [parsedData[key], value];
        }
      } else {
        // If the key doesn't exist, create a new key-value pair
        parsedData[key] = value;
      }
    });
    return parsedData
  }