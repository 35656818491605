import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import styles from './text-signature.module.scss';

const HiddenCanvas = forwardRef(function HiddenCanvas({ name, initials = "" }, ref) {
    useEffect(() => {
        const canvas = ref.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ctx.font = "6rem Sassy Frass";
        ctx.fillStyle = "#000000";

        // const nameWidth = ctx.measureText(name).width;
        // const initialsWidth = ctx.measureText(initials).width;

        ctx.fillText(name, 50, 100);
        // ctx.fillText(initials, 50 + nameWidth + 50, 100);
    }, [name, initials, ref]);

    return (
        <canvas ref={ref} width={800} height={150} style={{ display: "none" }} />
    );
})

HiddenCanvas.propTypes = {
    name: PropTypes.string.isRequired,
    initials: PropTypes.string,
}

const TextSignature = forwardRef(function TextSignature(_, ref) {
    const [name, setName] = useState('')
    // const [initials, setInitials] = useState('')
    const canvasRef = useRef()

    useEffect(() => {
        ref.current = {
            text: ref?.current?.text ?? '',
            getCanvas: () => canvasRef.current
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="">
            <div className={styles.wrapper}>
                <HiddenCanvas name={name} /* initials={initials} */ ref={canvasRef} />
                <div className={styles.fields}>
                    <div className={styles.name}>
                        <input value={name} onChange={(e) => {
                            // ref.current.text = [e.target.value, initials].join('')
                            ref.current.text = e.target.value
                            setName(e.target.value)
                        }} />
                    </div>
                    {/* <div className={styles.initials}>
                        <input value={initials} onChange={(e) => {
                            ref.current.text = [name, e.target.value].join('')
                            setInitials(e.target.value)
                        }} />
                    </div> */}
                </div>
            </div>
        </div>
    )
})

export default TextSignature