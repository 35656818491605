export function isEmpty(value) {
    if (value == null) {
        return true; // null or undefined
    }
    if (typeof value === 'object') {
        return Object.keys(value).length === 0;
    }
    if (typeof value === 'string' || Array.isArray(value)) {
        return value.length === 0;
    }
    return false;
}

export function isNil(value) {
    return value == null; // Equivalent to value === null || value === undefined
}

export function omitBy(obj, predicate) {
    return Object.fromEntries(
        Object.entries(obj).filter(([, value]) => !predicate(value))
    );
}
