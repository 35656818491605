import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { redirect, useFetcher, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { approveAdditionalQuotation } from '../../../api/additional-quotation/additional-quotation.js';
import { uploadFileFromCanvas, uploadFileFromObject } from '../../../api/common/file-upload.js';
import { DrawSignature, TextSignature, UploadSignature } from '../../../components/signature';
import { Tabs } from '../../../components/ui';
import i18n from '../../../i18n.js';
import { generateRouteUrl } from '../../../library/constants/routes.jsx';

const AdditionalQuotationSignature = () => {
    const { quotationToken } = useParams()
    const navigate = useNavigate()
    const signatureMode = useRef('draw')
    const drawSignCanvasRef = useRef(null)
    const fetcher = useFetcher()
    const textSignCanvasRef = useRef(null)
    const uploadSignRef = useRef(null)
    const { t } = useTranslation()
    const handleSignClick = async () => {
        let signatureId;
        // eslint-disable-next-line default-case
        switch (signatureMode.current) {
            case "draw":
                //Check if signature filled
                if (drawSignCanvasRef.current.isEmpty()) {
                    alert(t('Signature is required'))
                    return
                }
                signatureId = await uploadFileFromCanvas(quotationToken, drawSignCanvasRef.current.getCanvas())
                break;

            case "type":
                //Check if signature filled
                if (textSignCanvasRef.current.text.length === 0) {
                    alert(t('Signature is required'))
                    return
                }
                signatureId = await uploadFileFromCanvas(quotationToken, textSignCanvasRef.current.getCanvas())
                break;

            case "upload":
                //Check if signature filled
                if (!uploadSignRef.current || !(uploadSignRef.current instanceof File)) {
                    alert(t('Signature is required'))
                    return
                }
                signatureId = await uploadFileFromObject(quotationToken, uploadSignRef.current)
                break;
        }

        if (!signatureId) {
            throw new Response(t('Unable to upload signature'), { status: 500 })
        }

        fetcher.submit({
            signatureId,
            action: 'sign'
        }, {
            method: 'post',
            encType: 'application/json'
        })
        //Quotation approved successfully
    }

    return (
        <div className="wrapper quotation-wrapper">
            <div className="main">
                <div className="box--white">
                    <p>{t("Your signature is required to confirm the order.")}</p>
                    <Tabs onTabChange={(activeTab) => {
                        signatureMode.current = activeTab
                    }}>
                        <div name="draw" label={<><img src="/icons/signature.svg" alt='' /> {t("Draw Signature")}</>}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <p><strong>{t("Signature")}</strong></p>
                                <p>
                                    <button className='link' onClick={() => { drawSignCanvasRef.current.clear() }}>{t("Clear")}</button>
                                </p>
                            </div>
                            <DrawSignature ref={drawSignCanvasRef} />
                        </div>
                        <div name="type" label={<><img src="/icons/text.svg" alt='' /> {t("Type Signature")}</>}>
                            <p>{t("Confirm your name and choose signature style")}</p>
                            <p>{t("Full Name Signature")}</p>
                            <TextSignature ref={textSignCanvasRef} />
                        </div>
                        <div name="upload" label={<><img src="/icons/upload.svg" /> {t("Upload Signature")}</>}>
                            <UploadSignature ref={uploadSignRef} />
                        </div>
                    </Tabs>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <p>{t("By clicking on signature confirm, I understand that I am signing this document. ")}</p>
                        <div className="actions">
                            <button className="button--primary" onClick={handleSignClick}>{t("Sign and Confirm")}</button>
                            <button className="button--danger" onClick={() => {
                                navigate(generateRouteUrl('QuotationDetails', { quotationToken }))
                            }}>{t("Cancel")}</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Modal title="Confirmation letter" isVisible={isApproved}>
                <p>{t("Your Confirmation Letter has been sent to Leoprinting")}.</p>
                <div className="actions">
                    <button className="button--primary" onClick={() => {
                        navigate(generateRouteUrl('AdditionalQuotationFinal', { quotationToken, quotationId, itemId: item?.id }))
                    }}>Continue</button>
                </div>
            </Modal > */}
        </div >
    )
}


AdditionalQuotationSignature.Actions = {
    sign: async ({ params, data }) => {
        const { quotationToken, quotationId, itemId } = params
        const { signatureId } = data
        try {
            const msg = await approveAdditionalQuotation(quotationToken, quotationId, signatureId)
            toast.success(i18n.t(msg))
            return redirect(generateRouteUrl('AdditionalQuotationFinal', { quotationToken, quotationId, itemId: itemId }))
        }
        catch (e) {
            toast.error(i18n.t(e?.message))
            return redirect(generateRouteUrl('Dashboard', { quotationToken }))
        }
    },


}

// AdditionalQuotationSignature.Loader = async ({ params }) => {
//     const { quotationToken } = params

//     //call quotation details API to check authentication status
//     const { project_id, quotation = null } = await getQuotationDetails(quotationToken)

//     if (!quotation && project_id) {
//         return redirect(generateRouteUrl('Dashboard', { quotationToken }))
//     }

//     const selectedItems = LocalStorage(quotationToken).get('selectedQuotationItems') || {}

//     if (isEmpty(selectedItems)) {
//         return redirect(generateRouteUrl('QuotationDetails', { quotationToken }))
//     }∂

//     return { selectedItems }
// }



export default AdditionalQuotationSignature
