import React, { forwardRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import styles from './upload-signature.module.scss';

const UploadSignature = forwardRef(function UploadSignature(_, ref) {
    const [, setFile] = useState()
    const { t } = useTranslation()
    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        maxSize: 500 * 1024,
        accept: {
            'image/png': ['.png'],
            'image/jpg': ['.jpg', '.jpeg']
        },
        onDropRejected: rejections => {
            alert(rejections.map(({ errors }) => errors.map(({ message }) => message)).join("\n"))
        },
        onDrop: acceptedFiles => {
            if (acceptedFiles[0]) {
                setFile(Object.assign(acceptedFiles[0], {
                    preview: URL.createObjectURL(acceptedFiles[0])
                }));
                ref.current = acceptedFiles[0]
            }
        }
    })
    return (
        <div className="" style={{marginTop: "20px"}}>
            <div className={styles.wrapper}>
                <div {...getRootProps({ className: styles.dropArea })}>
                    <div className={styles.dropIcon}>
                        <img src="/icons/upload-drop.svg" />
                    </div>
                    <div className={styles.dropText}>
                        {t('Drag and drop your signature')}
                    </div>
                    <p>------------ {t("OR")} ------------</p>
                    <input className="input-zone" {...getInputProps()} />
                    <div className={`button--primary ${styles.uploadButton ?? ''}`}>{t('Browse your file')}</div>

                    {!!ref.current && (<div key={ref.current.name}>
                        <img
                            src={ref.current.preview}
                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                            // Revoke data uri after image is loaded
                            onLoad={() => { URL.revokeObjectURL(ref.current.preview) }}
                        />
                    </div>)}
                </div>
            </div>
        </div>
    )
})

// UploadSignature.propTypes = {
//     onUpload: PropTypes.func.isRequired
// }

export default UploadSignature