import React from 'react';
import { Link, useParams, useRouteLoaderData } from 'react-router-dom';
import { AdditionalQuotationItem } from '../../../components/quotation';
import { generateRouteUrl } from '../../../library/constants/routes.jsx';
import { formatPrice, getFilePath } from '../../../library/utilities';
// import { LocalStorage } from '../../../library/utilities/localstorage.js';
import { useTranslation } from 'react-i18next';
import styles from './additional-quotation.module.scss';

const AdditionalQuotationDetails = () => {
    const { quotationToken } = useParams()
    const { quotationDetails, item } = useRouteLoaderData('AdditionalQuotation')
    const { t } = useTranslation()

    return (
        <div className="wrapper quotation-wrapper">
            <div className="main">
                <h2>{t("Additional Quotation")}</h2>
                <AdditionalQuotationItem itemDetails={item} />
            </div>
            <div className="sidebar">
                <div className="box--gray">
                    <h3>{t("Summary")}</h3>
                    <div className={styles.totals}>
                        <table className={styles.totalRow}>
                            <thead>
                                <tr>
                                    <td className={styles.tableHeading}>{t("Item Name")}</td>
                                    <td className={styles.tableHeading}>{t("Cost")}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    quotationDetails.item_additional_quotation_lines.map((line) => {
                                        return <tr key={line?.id}>
                                            <td className={styles.name}>{line?.name}<br /> <span className={styles.desc}>{line?.description}</span> </td>
                                            <td className={styles.tableValuePadding}>{formatPrice(line?.sales_price, item?.currency?.code)}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>{t("Net Amount")}</td>
                                    <td> {formatPrice(quotationDetails.total_price, item?.currency?.code)}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div className="actions--expand">
                    <Link className="button--primary button--large" to={generateRouteUrl('AdditionalQuotationPreview', { quotationToken, itemId: item.id, quotationId: quotationDetails.id })} >{t("Confirm Additional Cost")}</Link>
                </div>
                <Link to={getFilePath(quotationDetails.pdf?.quotation, quotationToken)} className={styles.quotationDownload} reloadDocument download target="_self">
                    <div className={styles.quotationDownload__text}>
                        <h3>{t("Download Additional Quotation PDF")}</h3>
                    </div>
                    <img src="/icons/pdf.svg" alt='pdf' className={styles.quotationDownload__icon} />
                </Link>
            </div>
        </div>
    )
}

export default AdditionalQuotationDetails
