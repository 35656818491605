import PropTypes from 'prop-types';
import React from 'react';
import styles from './steps.module.scss';

const Steps = ({ steps }) => (
    <div className={styles.progressSteps}>
        {steps.map(({ name, label, icon }) => (
            <div key={name} className={styles.step}>
                <span className={styles.stepLabel}>{label}</span>
                <div className={styles.stepItem}>
                    <div className={styles.stepIcon}>
                        <img src={icon} alt={name} />
                    </div>
                </div>
            </div>
        ))}
    </div>
)

Steps.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.node.isRequired,
        icon: PropTypes.string.isRequired,
    })),
    currentStep: PropTypes.number,
}

export default Steps