import React from 'react';
import { generatePath, Navigate } from 'react-router-dom';
import AddAddressForm from '../../components/forms/add-address-form.jsx';
import { ClientArtwork, Dashboard, ProjectItemAddress } from '../../routes/dashboard';
import { AdditionalQuotationDetails, AdditionalQuotationFinal, AdditionalQuotationPreview, AdditionalQuotationSignature } from '../../routes/dashboard/additional-quotation';
import AdditionalQuotationRoot from '../../routes/dashboard/additional-quotation/additional-quotation-root.jsx';
import DashboardRoot from '../../routes/dashboard/dashboard-root.jsx';
import FilesRoot from '../../routes/files/Files.jsx';
import OrderDetails from '../../routes/orderDetails/orderDetails.jsx';
import { QuotationDetails, QuotationFinal, QuotationPreview, QuotationSignature } from '../../routes/quotation';
import QuotationRejected from '../../routes/quotation/quotation-rejected.jsx';
import QuotationRoot from '../../routes/quotation/quotation-root.jsx';
import QuotationVisual from '../../routes/quotation/quotation-visual.jsx';

export const routesList = [
  {
    url: '/dashboard/:quotationToken',
    id: 'DashboardRoot',
    component: DashboardRoot,
    children: [
      {
        index: true,
        isProtected: true,
        // component: () => {
        //   const { quotationToken } = useParams()
        //   return (
        //     <Navigate to={generatePath('/dashboard/:quotationToken/home')} replace />
        //   )
        // },
        component: () => <Navigate to='./home' replace />
      },
      {
        label: 'Dashnoard',
        title: 'Dashboard',
        id: 'Dashboard',
        isProtected: true,
        component: Dashboard,
        url: 'home'
      },
      {
        label: 'Client Artwork',
        title: 'Client Artwork',
        component: ClientArtwork,
        id: 'ClientArtwork',
        url: ':itemId/clientartwork',
        isProtected: true,
      },
      {
        label: 'Manage Addresses',
        title: 'Manage Addresses',
        component: ProjectItemAddress,
        id: 'ProjectItemAddress',
        url: ':itemId/addresses',
        isProtected: true,
        children: [
          {
            label: 'Add Addresses',
            title: 'Add Addresses',
            component: AddAddressForm,
            id: 'AddAddressForm',
            url: 'add',
            isProtected: true,
          },
        ]
      },
      {
        label: 'Order Details',
        title: 'Order Details',
        url: ':itemId/details',
        component: OrderDetails,
        id: 'OrderDetails',
        isProtected: true
      },
      {
        url: 'files',
        id: 'Files',
        children: [
          {
            label: 'Files ',
            title: 'Files',
            isProtected: true,
            component: FilesRoot,
          }
        ]
      },
    ]
  },
  // {
  //   url: '/files/:quotationToken',
  //   id: 'Files',
  //   children: [
  //     {
  //       label: 'Files ',
  //       title: 'Files',
  //       isProtected: true,
  //       component: FilesRoot,
  //     }
  //   ]
  // },
  {
    url: '/quotation/:quotationToken',
    component: QuotationRoot,
    id: 'QuotationRoot',
    isProtected: false,
    children: [
      {
        label: 'Quotation Review',
        title: 'Quotation Review',
        component: QuotationDetails,
        id: 'QuotationDetails',
        isProtected: false,
      },
      {
        label: 'Quotation Rejected',
        title: 'Quotation Rejected',
        component: QuotationRejected,
        url: '/quotation/:quotationToken/rejected',
        id: 'QuotationRejected',
        isProtected: false,
      },
      {
        label: 'Visual',
        title: 'Visual',
        url: '/quotation/:quotationToken/visual',
        component: QuotationVisual,
        id: 'QuotationVisual',
        isProtected: false,
      },
      {
        label: 'Quotation Preview',
        title: 'Quotation Preview',
        url: '/quotation/:quotationToken/preview',
        component: QuotationPreview,
        id: 'QuotationPreview',
        isProtected: false
      },
      {
        label: 'Quotation Signature',
        title: 'Quotation Signature',
        url: '/quotation/:quotationToken/sign',
        component: QuotationSignature,
        id: 'QuotationSignature',
        isProtected: true
      },
    ]
  },
  {
    label: 'Quotation Final Review',
    title: 'Quotation Final Review',
    url: '/approvedquotation/:quotationToken/final/:quotationId',
    component: QuotationFinal,
    id: 'QuotationFinal',
    isProtected: true
  },

  {
    url: '/additional-quotation/:quotationToken/:itemId/:quotationId',
    isProtected: false,
    id: 'AdditionalQuotation',
    component: AdditionalQuotationRoot,
    children: [
      {
        label: 'Additional Quotation',
        title: 'Additional Quotation',
        component: AdditionalQuotationDetails,
        id: 'AdditionalQuotationDetails',
        isProtected: false,
      },
      {
        label: 'Additional Quotation Preview',
        title: 'Additional Quotation Preview',
        url: '/additional-quotation/:quotationToken/:itemId/:quotationId/preview',
        component: AdditionalQuotationPreview,
        id: 'AdditionalQuotationPreview',
        isProtected: false
      },
      {
        label: 'Additional Quotation Signature',
        title: 'Additional Quotation Signature',
        url: '/additional-quotation/:quotationToken/:itemId/:quotationId/sign',
        component: AdditionalQuotationSignature,
        id: 'AdditionalQuotationSignature',
        isProtected: true
      },
      {
        label: 'Final Additional Quotation',
        title: 'Final Additional Quotation',
        url: '/additional-quotation/:quotationToken/:itemId/:quotationId/final',
        component: AdditionalQuotationFinal,
        id: 'AdditionalQuotationFinal',
        isProtected: true
      },
      // {
      //   label: 'Quotation Final Review',
      //   title: 'Quotation Final Review',
      //   url: 'final/:quotationId',
      //   component: QuotationFinal,
      //   isProtected: true
      // },
    ]
  },
];

const flattenRoutes = (route, parent = []) => (
  route.children ?
    [{ url: route?.url ? (route.url.startsWith('/') ? route.url : parent + '/' + route.url) : parent, component: route?.component, id: route?.id }, ...route.children.map(_r => flattenRoutes(_r, [...parent, route?.url]))] :
    { url: route?.url ? (route.url.startsWith('/') ? route.url : parent + '/' + route.url) : parent, component: route?.component, id: route?.id }
)

export const generateRouteUrl = (routeId, params = {}) => {
  const matchedRoute = routesList.map(route => flattenRoutes(route))
    .flat(Infinity)
    .filter(route => route?.id)
    .find(route => route?.id === routeId)

  if (!matchedRoute)
    throw new Error(`Unable to generate URL: Invalid Route Id: ${routeId}`)

  let url = ''
  if (Array.isArray(matchedRoute.url)) {
    for (const i of matchedRoute.url.reverse()) {
      url += i
      if (i.startsWith('/')) break
    }
  }
  else {
    url = matchedRoute.url
  }

  return generatePath(url, params)
}