import React from 'react';
import { Outlet, redirect, useLoaderData, useNavigation } from 'react-router-dom';
import { getProjectItemDetails } from '../../../api/project/project-item.js';
import { getProjectDetails } from '../../../api/project/project.js';
import { getAdditionalQuotationDetails } from '../../../api/quotation/quotation.js';
import { changeLanguage } from '../../../App.jsx';
import Footer from '../../../components/layout/footer.jsx';
import { HeaderBar } from '../../../components/layout/header-bar.jsx';
import Header from '../../../components/layout/header.jsx';
import { AdditionalQuotationStatusText } from '../../../library/constants/client-statuses.js';
import { generateRouteUrl } from '../../../library/constants/routes.jsx';


const AdditionalQuotationRoot = () => {
    const navigation = useNavigation()
    const { id, userLanguage } = useLoaderData()   //project id fetching

    return (
        <>
            <Header userLanguage={userLanguage} />
            <HeaderBar projectId={id} language={userLanguage} />
            <div className={`container page ${["loading", "submitting"].includes(navigation.state) ? 'loading' : ''}`}>
                <Outlet />
            </div>
            <Footer userLanguage={userLanguage} />
        </>
    );
}

AdditionalQuotationRoot.Loader = async ({ params }) => {
    const { quotationToken, quotationId } = params
    const item = await getProjectItemDetails(quotationToken, params.itemId)
    const { id, cms_language_code } = await getProjectDetails(quotationToken)
    const quotationDetails = await getAdditionalQuotationDetails(quotationToken, quotationId)
    if (quotationDetails.status === AdditionalQuotationStatusText.Approved || quotationDetails.status === AdditionalQuotationStatusText.Cancel) {
        return redirect(generateRouteUrl('Dashboard', { quotationToken }))
    }
    let userLanguage = navigator.language.split('-')?.[0] ?? 'en'
    if (cms_language_code) {
        userLanguage = cms_language_code.split('_')?.[1] ?? cms_language_code
    }
    changeLanguage(userLanguage)

    return { item, quotationDetails, id, userLanguage }
}

export default AdditionalQuotationRoot
