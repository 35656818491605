import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { redirect, useLoaderData, useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';
import { getQuotationDetails } from '../../api/quotation/quotation.js';
import { changeLanguage } from '../../App.jsx';
import { QuotationItemPreview } from '../../components/quotation';
import { Modal } from '../../components/ui';
import { Entities } from '../../library/constants';
import { baseUrls } from '../../library/constants/cms.js';
import { generateRouteUrl } from '../../library/constants/routes.jsx';
import { AddOrSubractDays, formatDate, formatPrice } from '../../library/utilities';
import { isEmpty } from '../../library/utilities/common.js';
import { LocalStorage } from '../../library/utilities/localstorage.js';
import styles from './quotation-preview.module.scss';

const Address = ({ address }) => (
    <>
        {address.street} {address.street_number} <br />
        {(address.additional_field_1 || address.additional_field_2) && (
            <>
                {address.additional_field_1} {address.additional_field_2} <br />
            </>
        )}
        {address.zipcode} <br />
        {address.city} <br />
        {address.county ? (
            <>
                {address.county} <br /> {address.country.name}
            </>
        ) : (
            address.country.name
        )}
    </>
)
Address.propTypes = {
    address: PropTypes.object
}

const QuotationPreview = () => {
    const { t } = useTranslation()
    const { quotationToken } = useParams()
    const { selectedItems, userLanguage } = useLoaderData()
    const { quotation, footer } = useRouteLoaderData('QuotationRoot')

    const quotationSelected = useMemo(() => ({
        ...quotation,
        items: quotation.items.map((item) => ({
            ...item,
            price: item.prices.find(({ id }) => {
                return id === selectedItems?.[item.id]
            })
        })).filter(({ price }) => !!price)
    }), [selectedItems, quotation])

    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        const modalTimer = setTimeout(() => {
            setIsConfirmModalVisible(true);
        }, 3 * 1000);

        return () => {
            clearTimeout(modalTimer);
        };
    }, []);

    const totalVat = quotationSelected.items.reduce((acc, { price }) => acc + parseFloat(price.vat?.vat_amount ?? 0), 0)
    const selectedItemsTotal = quotationSelected.items.reduce((acc, { price }) => acc + parseFloat(price.total_price_excl_vat), 0) + totalVat
    const vatLines = quotationSelected.items.reduce((acc, { price }) => {
        if (!acc[price.vat.vat_rate]) {
            acc[price.vat.vat_rate] = 0
        }
        acc[price.vat.vat_rate] += price.vat.vat_amount
        return acc
    }, {})

    const handleSignClick = () => {
        //Show quotation preview
        navigate(generateRouteUrl('QuotationSignature', { quotationToken }))
    }

    // Dynamically determine the contact URL based on userLanguage
    const contactUrl = baseUrls[userLanguage]
        ? `${baseUrls[userLanguage]}/contact`
        : `${baseUrls.en}/contact`;

    return (
        <div className="wrapper quotation-wrapper">
            <Modal title={t("Review Quotation")} onClose={() => setIsConfirmModalVisible(false)} isVisible={quotationSelected.can_sign && isConfirmModalVisible}>
                <p>
                    {t("Dear {{first_name}} {{last_name}},", {
                        first_name: quotationSelected.contact.first_name,
                        last_name: quotationSelected.contact.last_name
                    })}
                </p>
                <p>
                    <Trans
                        defaults='To avoid any mistakes, we kindly ask you to carefully review the quotation. Are all the details correct and everything as you wish? Then you can sign the quotation to finalise your order.  Do you have any questions or would you like to discuss something? Feel free to <ContactLink />, we are happy to assist you.'
                        components={{ ContactLink: <a href={contactUrl} target="_blank" rel="noopener noreferrer">{t('contact us')}</a> }}
                    />
                </p>
                <button className="actions button--primary" onClick={() => setIsConfirmModalVisible(false)}>{t("Continue")}</button>
            </Modal>
            <div className="main">
                <div className="inner-wrapper">
                    <div className="inner-wrapper--title">{t("Quotation Final Preview")}</div>
                    <div className={`inner-wrapper--body ${styles.wrapper}`}>
                        <div className={styles.header}>
                            <div className={styles.logo}>
                                <img src='/images/logo.svg' alt="Leoprinting" />
                            </div>
                            <img src="/images/tagline.svg" alt="tagline" />
                        </div>
                        <div className={`box--large box--light-gray`}>
                            <div className="col" style={{ gridRow: "1/4" }}>
                                <h3>{quotationSelected.customer.company}</h3>
                                <Address address={quotationSelected.address} />
                            </div>
                            <div className="col">
                                <h3>{t('Quotation Reference')}</h3>
                                {quotationSelected.id}
                            </div>
                            <div className="col" style={{ gridRow: "2/4" }}>
                                <h3>{t("Contact Name")}</h3>
                                {quotationSelected.account_manager.name}
                            </div>
                            <div className="col">
                                <h3>{t("Quotation Date")}</h3>
                                {formatDate(quotationSelected.createdAt)}
                            </div>
                            <div className="col">
                                <h3>{t("Quotation Valid Upto")}</h3>
                                {formatDate(AddOrSubractDays(new Date(quotationSelected.createdAt), 30, true))}
                            </div>
                            {quotationSelected.client_order_number && (
                                <div className="col">
                                    <h3>{t("Client Order Number")}</h3>
                                    {quotationSelected.client_order_number}
                                </div>
                            )}
                        </div>
                        <div className="box--large">
                            <p>{t("Dear")} {quotationSelected.primary_contact.first_name} {quotationSelected.primary_contact.last_name},</p>
                            <p>{t("It is our pleasure to make the following quotation:")}</p>
                        </div>
                        {quotationSelected.items.map(item => <QuotationItemPreview key={item.id} item={item} currency={quotationSelected.currency} />)}
                    </div>
                </div>
                <div className="inner-wrapper" style={{ marginTop: 'var(--space-xxlarge)' }}>
                    <div className={`inner-wrapper--body ${styles.wrapper}`}>
                        {quotationSelected.client_comment?.length > 0 && (
                            <div className="box--large box--light-gray">
                                <h3>{t("Client Note")}</h3>
                                {quotationSelected.client_comment}
                            </div>
                        )}
                        <div className="box--light-gray" style={{ gap: 'var(--space-large)' }}>
                            <div className="box--dark-gray col">
                                <h4>{t("File Delivery")}</h4>
                                <p className='nomargin'>
                                    <Trans
                                        defaults='PDF or EPS file. Any questions? Please <ContactLink />.'
                                        components={{
                                            ContactLink: <a href={contactUrl} target="_blank" rel="noopener noreferrer">{t('contact us')}</a>
                                        }}
                                    />
                                </p>
                            </div>
                            <div className="box--dark-gray col">
                                <h4>{t("Prices")}</h4>
                                <p className='nomargin'>
                                    <span style={{ display: 'block', marginBottom: '0.5rem' }}>{t("Inclusive setup costs")}</span>
                                    <span style={{ display: 'block', marginBottom: '0.5rem' }}>{t("Inclusive studio support")}</span>
                                    <span style={{ display: 'block', marginBottom: '0.5rem' }}>{t("Inclusive digital proof for approval")}</span>
                                    <span style={{ display: 'block', marginBottom: '0.5rem' }}>{t("Exclusive VAT")}</span>
                                </p>
                            </div>
                        </div>
                        <div className="box--large box--light-gray">
                            <p>{t("This quotation is valid for 30 days from the date of stated on the quotation. Please note that additional costs may apply based on Brexit Ruling. Please notify us of any mistakes. Your business is of great value to us and we hope we can be of service to you. The image(s) in this quotation are an indication only, the customised production will deviate from this.")}</p>
                            <h3>{t("Items and Services")}</h3>
                            <div className={styles.totals}>
                                <table className={styles.totalRow}>
                                    <thead>
                                        <tr>
                                            <th><strong>{t("Item Name")}</strong></th>
                                            <th><strong>{t("Quantity")}</strong></th>
                                            <th><strong>{t("Sales Price/Piece")}</strong></th>
                                            <th><strong>{t("VAT")}</strong></th>
                                            <th><strong>{t("Total Price")}</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {quotationSelected.items.map(item => (
                                            <tr key={item.id}>
                                                <td>{item.product.name}</td>
                                                <td>{item.price.quantity}</td>
                                                <td>{formatPrice(item.price.sales_price, quotationSelected.currency.code)}</td>
                                                <td>{!!item.price.vat && `${item.price.vat.vat_rate}%`}</td>
                                                <td>{formatPrice(item.price.total_price_excl_vat, quotationSelected.currency.code)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr style={{ borderBottom: '1px var(--color-green-line) solid' }}></tr>
                                        <tr>
                                            <td>{t('Sub Total')}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{formatPrice(selectedItemsTotal - totalVat, quotation.currency?.code)}</td>
                                        </tr>
                                        {Object.values(vatLines)?.length > 1 && Object.entries(vatLines).map(([vat_rate, vat_amount]) => (
                                            <tr key={vat_rate}>
                                                <td><i>{t('VAT Rate')} - {vat_rate}%</i></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>{formatPrice(vat_amount, quotation.currency?.code)}</td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td>{t('Total VAT')}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{formatPrice(totalVat, quotation.currency?.code)}</td>
                                        </tr>
                                        <tr>
                                            <th colSpan="4">{t("Net Amount")}</th>
                                            <th>{formatPrice(selectedItemsTotal, quotationSelected.currency.code)}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        {[Entities.NL, Entities.BE, Entities.DE].includes(quotation.entity_id) && (
                            <div className="box--large box--light-gray">
                                <h3>{t('Important Notice Regarding VAT')}:</h3>
                                <p>{t('This order confirmation includes VAT by default. If the order qualifies for an intra-Community supply or export and you have a valid VAT number, you can provide it to us. In that case, we will issue the final invoice without VAT. Please provide us with the VAT number as soon as possible.')}</p>
                            </div>
                        )}
                        {quotationSelected.can_sign ? (
                            <div className="box--large box--light-gray">
                                <h3>{t("Signature")}</h3>
                                <p>{t("Clicking 'Sign' will initiate a verification process. You'll receive an email with a unique verification number")}</p>
                                <div className={`box--large ${styles.signatureContainer}`}>
                                    <div className={styles.contact}>
                                        <div className={styles.name}>{quotationSelected.contact.first_name} {quotationSelected.contact.last_name}</div>
                                        <div className={styles.email}>{quotationSelected.contact.email}</div>
                                    </div>
                                    <button onClick={handleSignClick} className="button--primary">{t("Click to Sign")}</button>
                                </div>
                                <br />
                                <br />
                                <strong>{t("Contact")} :</strong>
                                <div className={styles.name}>{quotationSelected.account_manager.name}</div>
                                <div className={styles.email}>{quotationSelected.account_manager.email}</div>
                            </div>
                        ) : (
                            <div className="box--large box--light-gray">
                                <strong>{t('You are not authorized to approve this quotation')}</strong>
                            </div>
                        )}
                        {/* <div className="box center">
                            <p><strong>{t("Passion for creating your excellent promotion.")} </strong></p>
                            <p>{t("80-83 Long Lane, London EC1A 9ET  -  Tel: +44 20 3608 1945  -  E-mail: info@leoprinting.com")}<br />{t("Private Limited Company Number 765685")}</p>
                            <hr style={{ width: '40%', minWidth: '250px' }} />
                            <p>{t("Leoprinting is part of Leogroup")}.</p>
                        </div> */}
                        <div className="box center" dangerouslySetInnerHTML={{ __html: footer }} />
                    </div>
                </div>
            </div>
        </div >
    )
}


QuotationPreview.Loader = async ({ params }) => {
    const { quotationToken } = params
    const selectedItems = LocalStorage(quotationToken).get('selectedQuotationItems') || {}
    const { cms_language_code } = await getQuotationDetails(quotationToken)
    let userLanguage = navigator.language.split('-')?.[0] ?? 'en'
    if (cms_language_code) {
        userLanguage = cms_language_code.split('_')?.[1] ?? cms_language_code
    }
    changeLanguage(userLanguage)

    if (isEmpty(selectedItems)) {
        return redirect(generateRouteUrl('QuotationDetails', { quotationToken }))
    }

    return { selectedItems, userLanguage }
}

export default QuotationPreview
