import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { AddressBox } from '../data';
import { Modal, Selectable } from '../ui';
import styles from './address-selector.module.scss';

const AddressSelector = ({ name = null, addresses, selectedAddressId = null, excludedAddresses = [], onChange }) => {
    const [viewAddressVisible, setViewAddresVisible] = useState(false)
    const availableAddresses = excludedAddresses ? addresses.filter(({ id }) => !excludedAddresses.includes(id)) : addresses
    const preferredAddresses = useMemo(() => {
        //if there is selected address , keep it on top
        if (!selectedAddressId || addresses.findIndex(a => a.id === selectedAddressId) < 3) {
            return availableAddresses.slice(0, 3)
        }
        return [availableAddresses.find(a => a.id === selectedAddressId), ...availableAddresses.slice(0, 2)]

    }, [availableAddresses, addresses, selectedAddressId])

    return (
        <div className={styles.addressSelector}>
            <Selectable
                items={preferredAddresses}
                onSelectionChange={(id) => { onChange(id) }}
                renderItem={(address, isSelected) => <AddressBox key={address.id} id={address.id} address={address} isSelected={isSelected} />}
                selected={selectedAddressId}
                className={styles.addresses}
            />
            <div className="actions">
                {availableAddresses.length > 3 && (
                    <>
                        <Modal title="Select addresses" isVisible={viewAddressVisible} onClose={() => setViewAddresVisible(false)} >
                            <Selectable
                                items={availableAddresses}
                                onSelectionChange={(id) => {
                                    onChange(id)
                                    setViewAddresVisible(false)
                                }}
                                renderItem={(address, isSelected) => <AddressBox key={address.id} id={address.id} address={address} isSelected={isSelected} />}
                                selected={selectedAddressId}
                                className={styles.addresses}
                            />
                        </Modal>
                        <button onClick={() => setViewAddresVisible(true)} className="button">View All</button>
                    </>
                )}
                <Link to='./add' className="button--primary">Add New Address</Link>
            </div>
            {!!name && <input type="hidden" name={name} value={selectedAddressId} />}
        </div>
    )
}
AddressSelector.propTypes = {
    name: PropTypes.string,
    addresses: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired
    })),
    selectedAddressId: PropTypes.number,
    excludedAddresses: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func.isRequired,
}

export default AddressSelector