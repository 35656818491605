import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Page } from "../layout";
import styles from './error-404.module.scss';
import i18n from '../../i18n';

const Error404 = () => {
    const { t } = useTranslation()
    return (
        <Page className={styles.pagenotfound} userLanguage={i18n.language}>
            <h1>{t('The Quotation either invalid or no longer valid.')}</h1>
            <p>
                <Trans
                    defaults='If you think this is an error, please contact <EmailLink />'
                    components={{ EmailLink: <a href="mailto:admin@leoprinting.com">admin@leoprinting.com</a> }}
                />
            </p>
            <img className={styles.mainImage} src='/images/404-lion.svg' alt="404" />
            <div className="actions">
                <Link className="button" to={-1}><img src="/icons/arrow.svg" alt="back" /> {t('Back')}</Link>
                <Link className="button" to={0}>{t('Reload')}</Link>
            </div>
        </Page >
    )
}

export default Error404