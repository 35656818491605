import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styles from './modal.module.scss';

const Modal = ({ isVisible = false, title = "", width = '768px', onClose, closeButtonText, onOk, oKButtonText, okButtonProps, cancelButtonProps, children }) => {
    const [isClosing, setIsClosing] = useState(false);
    const [showModal, setShowModal] = useState(isVisible);

    // Sync showModal with isVisible and handle closing animations
    useEffect(() => {
        if (isVisible) {
            setShowModal(true);
        } else if (!isVisible && showModal) {
            // Trigger fade-out animation
            setIsClosing(true);
            setTimeout(() => {
                setIsClosing(false);
                setShowModal(false);
            }, 300); // Time matching the fadeOut animation duration
        }
    }, [isVisible, showModal]);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            onClose();
            setIsClosing(false);
        }, 300); // Time matching the fadeOut animation duration
    };

    if (!showModal) {
        return null;
    }

    return (
        <>
            <button className={`${styles.Overlay} ${isClosing ? styles.fadeOut : ''}`} onClick={handleClose} ></button>
            <dialog className={`${styles.Wrapper} ${isClosing ? styles.fadeOut : ''}`} style={{ width }} aria-labelledby="modal-title" open>
                <div className={styles.Header}>
                    <div className={styles.Title}>{title}</div>
                    {onClose != null && (
                        <div role='button' tabIndex={0} className={styles.Close} onClick={handleClose}>
                            <img src="/icons/close.svg" alt="Close" aria-label="Close modal" />
                        </div>
                    )}
                </div>
                <div className={styles.Body}>
                    <div className={`box ${styles.ContentBox}`}>
                        {children}
                    </div>
                </div>
                {(!!closeButtonText || !!oKButtonText) && (
                    <div className={styles.Footer}>
                        <div className="actions">
                            {!!closeButtonText && (
                                <button className="button--danger" onClick={handleClose} {...cancelButtonProps}>
                                    {closeButtonText}
                                </button>
                            )}
                            {!!oKButtonText && (
                                <button className="button--success button--primary" onClick={onOk} {...okButtonProps}>
                                    {oKButtonText}
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </dialog>
        </>
    );
};

Modal.propTypes = {
    isVisible: PropTypes.bool,
    title: PropTypes.string,
    width: PropTypes.string,
    onClose: PropTypes.func,
    closeButtonText: PropTypes.string,
    onOk: PropTypes.func,
    oKButtonText: PropTypes.string,
    okButtonProps: PropTypes.object,
    cancelButtonProps: PropTypes.object,
    children: PropTypes.node.isRequired
};

export default Modal;