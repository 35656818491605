import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// import { getEnvVariable } from '../../../../App.jsx';
import styles from '../../../../components/project/project-item.module.scss';
import { generateRouteUrl } from '../../../../library/constants/routes.jsx';
import { formatDate, getDayOfWeek } from '../../../../library/utilities/intl.js';
import { baseUrls } from '../../../../library/constants/cms.js';

const Delivered = ({ data, quotationToken }) => {
    const { t } = useTranslation()
    const userLanguage = data.cms_language_code

    // Dynamically determine the contact URL based on userLanguage
    const contactUrl = baseUrls[userLanguage]
        ? `${baseUrls[userLanguage]}/contact`
        : `${baseUrls.en}/contact`;

    return <div className={`box--white ${styles.projectItem}`}>
        <div className={`box--plain ${styles.tasksBox}`}>

            <h3 style={{ 'textAlign': 'start' }}>{t("Task For You")}</h3>
            <div className={`box--blue-light ${styles.task}`}>
                <div className={styles.taskContent}>

                    <span>
                        {t("Order delivered on {{day}}, {{date}}", {
                            day: getDayOfWeek(data?.delivery_date),
                            date: formatDate(data?.delivery_date)
                        })}
                    </span>
                </div>
                <div className={styles.taskAction}>
                    <Link to={generateRouteUrl('OrderDetails', {
                        quotationToken: quotationToken,
                        itemId: data?.id,
                    })} className={styles.button}>{t("View Order Details")}</Link>

                </div>
            </div>
            {data.rating_link && (
                <div className={`box--blue-light ${styles.task}`}>
                    <div className={styles.taskContent}>
                        <span>{t("How would you rate your expierience")}</span>
                    </div>
                    <div className={styles.taskAction}>
                        <Link to={data.rating_link} target="_blank" className={styles.button}>{t("Rate")}</Link>
                    </div>
                </div>
            )}
            <div className={`box--blue-light ${styles.task}`}>
                <div className={styles.taskContent}>
                    <span>{t("Need help or have a complain with your order ?")}</span>
                </div>
                <div className={styles.taskAction}>
                    <a href={contactUrl} className={styles.button} target="_blank" rel="noreferrer">{t("Help and Support")}</a>
                </div>
            </div>
        </div>
    </div>
}

Delivered.propTypes = {
    data: PropTypes.object,
    quotationToken: PropTypes.string,
}
export default Delivered